import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "./layout"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Head from "../components/head"

import { GatsbyImage } from "gatsby-plugin-image"
import {
  MetaFlex,
  Published,
  Tags,
  TimeToRead,
} from "../components/blogList/Posts"
import { Tag } from "../components/projectListing/ProjectContent"
import { mdxComponents } from "../components/mdx/mdx"
import styled from "styled-components"
import { ThemeContext } from "../components/ThemeContext"

export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        tags
        coverImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        theme {
          background_base
          theme_accent
          theme_secondary_accent
          theme_auxiliary
          theme_text
          theme_gradient_1
          theme_gradient_2
          blend_mode
          background_24_dp_elevation
          background_48_dp_elevation
          background_64_dp_elevation
        }
      }
      body
      timeToRead
    }
  }
`
// fields {
// socialcard
// }

const ThemedLayout = styled(Layout)`
  ${props => props.theme}
`

function Project(props) {
  const { colorMode } = React.useContext(ThemeContext)

  const post = props.data.mdx
  const tagsArrayLength = post.frontmatter.tags.length
  // const socialImage =  post.fields.socialcard;

  let theme = ""

  if (colorMode !== "dark" && post.frontmatter.theme !== null) {
    theme = Object.entries(post.frontmatter.theme)
      .map(([property, value]) => {
        return `--color-${property.replace(/_/g, "-")}: ${value}`
      })
      .join(";\n")
  }

  return (
    <ThemedLayout theme={theme}>
      <Head title={post.frontmatter.title} />
      <header>
        <h1 className="title_header">{post.frontmatter.title}</h1>
        <MetaFlex justifyContent="space-between" pad={true}>
          <Published>{post.frontmatter.date}</Published>
          <TimeToRead>{`${post.timeToRead} minute${
            post.timeToRead > 1 ? "s" : ""
          }`}</TimeToRead>
        </MetaFlex>
        {post.frontmatter.tags && (
          <Tags>
            {post.frontmatter.tags.map((tag, index) => {
              const comma = index !== tagsArrayLength - 1 ? "," : ""
              return (
                <Tag key={`${post.id}-${tag}`}>
                  <Link to={`/tags/${tag}`}>
                    {tag}
                    {comma}
                  </Link>
                </Tag>
              )
            })}
          </Tags>
        )}
      </header>
      <GatsbyImage
        className="full-bleed"
        image={post.frontmatter.coverImage.childImageSharp.gatsbyImageData}
        alt={`Cover Image for ${post.frontmatter.title}`}
      />
      <MDXProvider components={mdxComponents}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </MDXProvider>
    </ThemedLayout>
  )
}

export default Project
